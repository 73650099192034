import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { TransactionDataSource } from "./transaction.datasource";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Transaction, TransactionFilter } from "app/types/domain/transaction";
import { ConfigService, Timeout } from "@yukawa/chain-base-angular-client";
import { QueryResult } from "@yukawa/chain-base-angular-domain";
import { createCleanedPartial } from "app/utils/object.utils";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
  })
export class TransactionService implements OnDestroy {
    
    private _httpClient = inject(HttpClient);
    private _transactions$ = new  BehaviorSubject<Transaction[]>([]);
    private _totalRows$ = new BehaviorSubject<number>(0);
    private _loading$ = new BehaviorSubject<boolean>(false);

    constructor(
        private _configService: ConfigService,
        private _router: Router
    ){}

    get transactions(): Observable<Transaction[]> {
        return this._transactions$.asObservable();
    }

    get totalRows(): Observable<number> {   
        return this._totalRows$.asObservable();
    }

    get loading(): Observable<boolean> {
        return this._loading$.asObservable();
    }

    queryTransactions(filter:TransactionFilter): Subscription {
        
        this._loading$.next(true);

        const cleanedFilter = createCleanedPartial<TransactionFilter>(filter);
        // persist filter in url
        const params = {
            ...cleanedFilter,
            firstResult: filter.pager?.firstResult,
            pageSize: filter.pager?.pageSize
        }
        delete params.pager;

        Object.entries(params).forEach(([key, value]) => {
            if(Array.isArray(value)){
                //@ts-ignore
                params[key] = value.join(',');
            }
        });
        
        this._router.navigate([], {
            queryParams: params,
            //queryParamsHandling: 'merge'
        });
        
        
        Object.entries(cleanedFilter).forEach(([key, value]) => {
            switch (key) {
                case 'buyer':
                case 'seller':
                case 'keyword':
                case 'product':
                        cleanedFilter[key] = "*"+value+"*";
                    break;
            }

        })
        console.log('querying transactions', cleanedFilter);
        return this._httpClient.post<QueryResult<Transaction>>(this._configService.formatUrl('transactionUrl') + "/query", cleanedFilter)
        .subscribe({
            next: (transactions: QueryResult<Transaction>) => {
                this._loading$.next(false);
                this._totalRows$.next(transactions.rows);
                this._transactions$.next(transactions.items);
            },
            error: (error:any) => {
                console.error(error);
            }
        });
    }

    ngOnDestroy(): void {
        this._transactions$.complete();
    }

}