

export function createCleanedPartial<T>(obj: Object): Partial<T> {
    return Object.entries(obj)
    .reduce(
        (acc, [key, value]) => {

            if (value !== null && value !== undefined && value !== "") {
                acc[key as keyof Object] = value ;
            }
            
            return acc;
        }, {} as Partial<Object>) as T;
}