import {Change, ChangeFilter, Changed, Created, EntityFilter} from '@yukawa/chain-base-angular-domain';
import { SupportStatus } from '../extended.transaction.types';

export enum DataImportStatus {
UPLOADING = "UPLOADING",
UPLOADED = "UPLOADED",
MAPPING = "MAPPING",
MAPPED = "MAPPED",
IMPORTING = "IMPORTING",
IMPORTED = "IMPORTED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.ErrorInfo <br/>
*/
export interface ErrorInfo {

/**
*	@example java.lang.NullPointerException
*/
errorClass?: string;

/**
*	@example file can not be null
*/
message?: string;
timestamp?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.FavFilter <br/>
*/
export interface FavFilter extends  Changed{
change?: Change;
favFilterId?: number;
filterJson?: string;
name?: string;
userId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.GenericEvent <br/>
*/
export interface GenericEvent <T> extends  Created{
created?: Change;

/**
*	@example afsdfasdg-aegaseg-sadg-ageg
*/
eventUuid?: string;

/**
*	@example uploadStarted
*/
name?: string;
payload?: T;
setPayload?: undefined | T;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.GenericEventFilter <br/>
*/
export interface GenericEventFilter extends  EntityFilter{
created?: ChangeFilter;
eventUuid?: string;

/**
*	@example ["uploadStarted"]
*/
name?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.LastTradeFileEvent <br/>
*/
export interface LastTradeFileEvent extends  GenericEvent<TradeFilePayload> {
relatedFileId?: number;
}

export enum SourceType {
FILE = "FILE"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.TradeFileEvent <br/>
*/
export interface TradeFileEvent extends  GenericEvent<TradeFilePayload> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.TradeFileEventFilter <br/>
*/
export interface TradeFileEventFilter extends  GenericEventFilter{

/**
*	@example 1
*/
companyId?: number;

/**
*	@example 101
*/
sourceId?: number;

/**
*	@example user1
*/
userName?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.TradeFilePayload <br/>
*/
export interface TradeFilePayload {

/**
*	@example 1
*/
companyId?: number;
details?: {[key: string]: Object};
errorInfo?: ErrorInfo;

/**
*	@example 150
*/
fileId?: number;

/**
*	@example Bangladesh Samples Aug19.xlsx
*/
fileName?: string;

/**
*	@example https://chem.dev.cloud.yukawa.de/files/150.xlsx
*/
fileUrl?: string;

/**
*	@example UPLOADED
*/
importStatus?: DataImportStatus;

/**
*	@example admin
*/
userName?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.Transaction <br/>
*/
export interface Transaction extends  Changed{

/**
*	@example Bayer AG
*/
buyer?: string;
change?: Change;

/**
*	@example 1
*/
companyId?: number;
date?: string;

/**
*	@example DEU
*/
exportCountry?: string;

/**
*	@example 290454
*/
hsCode?: string;

/**
*	@example USA
*/
importCountry?: string;

/**
*	@example 12.5
*/
price?: number;

/**
*	@example 0.25
*/
pricePerUnit?: number;

/**
*	@example Methan
*/
product?: string;

/**
*	@example 50
*/
quantity?: number;

/**
*	@example kg
*/
quantityUnit?: string;

/**
*	@example Seller Inc.
*/
seller?: string;

/**
*	@example 101
*/
sourceId?: number;

/**
*	@example 1
*/
transactionId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.TransactionFilter <br/>
*/
export interface TransactionFilter extends  EntityFilter{

/**
*	@example Bayer AG
*/
buyer?: string;

/**
*	@example 1
*/
companyId?: number;

/**
*	@example USA
*/
exportCountry?: string;

/**
*	@example DEU
*/
importCountry?: string;

/**
*	@example Methan
*/
product?: string;

/**
*	@example Seller Inc.
*/
seller?: string;

/**
*	@example 101
*/
sourceId?: string;

/**
*	@example 4769080
*/
transactionId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.TransactionSource <br/>
*/
export interface TransactionSource extends  Changed, Created{
change?: Change;

/**
*	@example 1
*/
companyId?: number;
created?: Change;

/**
*	@example sample.xls
*/
fileName?: string;
lastTradeFileEvent?: LastTradeFileEvent;
sourceId?: number;

/**
*	@example FILE
*/
type?: SourceType;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.transaction.TransactionSourceFilter <br/>
*/
export interface TransactionSourceFilter extends  EntityFilter{

/**
*	@example 1
*/
companyId?: number;
created?: ChangeFilter;

/**
*	@example sample.xls
*/
fileName?: string;

/**
*	@example 101
*/
sourceId?: number;

supportStatus?: SupportStatus;
}

